@use '../../styles/variables.scss';
@use '../../styles/mixins.scss';

.header-container {
  position: relative;
  height: variables.$headerHeight;
  background-color: variables.$surfaceInteractive;
  padding: 0px variables.$headerSidePadding;
  font-size: 20px;
  min-width: 1400px;

  .app-title {
    // Mixins takes argument for left padding value.
    padding: 10px variables.$appTitleLeftPadding;
  }
}
