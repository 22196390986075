@use '../../styles/variables.scss';

.help-message-container {
  width: 100%;
  color: variables.$textPassiveState;
  border: 1px solid variables.$textPassiveState;
  border-radius: 8px;
  padding: 10px;

  span {
    margin-left: 10px;
  }

  .close-message {
    cursor: pointer;
  }
}
