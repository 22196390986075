@use './styles/variables.scss';
@use './styles/bootstrap.scss';
@use './styles/mediaqueries.scss';
@use './styles/shared.scss';
@use './styles/mixins.scss';
@import 'rc-slider/assets/index.css';

html,
body {
  font-family: 'Inter', sans-serif;
  background-color: variables.$mainDark;
}

.main-app-container {
  width: 100%;
  padding-bottom: variables.$playerHeight;

  .main-app-holder {
    position: relative;
  }
}
