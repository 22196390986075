@use '../../../styles/variables.scss';
@use '../../../styles/mixins.scss';

.hidden-checkbox {
  position: absolute;
  opacity: 0;
  width: 0px;
  height: 0px;
}

.custom-checkbox {
  @include mixins.regularIcon(true);
  margin-right: 5px;
  background-image: url('../../../assets/icons/generalInteractionTickBoxPassive.svg');
}

.hidden-checkbox:checked + .custom-checkbox {
  background-image: url('../../../assets/icons/generalInteractionTickBoxActive.svg');
}
