@use '../../../../styles/variables.scss';
@use '../../../../styles/mixins.scss';

.left-sidebar-container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0px;
  height: 100vh;
  z-index: 1;
  background-color: variables.$sideContentBackground;
  min-width: variables.$leftSidebarWidth;
  margin-right: variables.$leftSidebarMargin;

  & > .container-resizing {
    overflow-y: auto;
    padding-bottom: variables.$playerHeight;

    & > .container-fixed {
      width: variables.$leftSidebarWidth;
      .app-title {
        @include mixins.sidebarLeftPadding();
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 25px;
        // Used as a placeholder for logo
        //width: 60px;
        //height: 23.08px;
      }

      .content-container {
        .content-item {
          @include mixins.sidebarLeftPadding();

          .title {
            color: variables.$textActiveStateWhite;
            margin-bottom: 25px;
          }

          .playlist-buttons-container {
            margin-bottom: 29px;
            .button-container {
              padding: 5px 0px;

              .add-button {
                @include mixins.regularIcon();
                border-radius: 1px;
                margin-right: 10px;
                background-image: url('../../../../assets/icons/closestTracksAddPassive1.svg');
              }

              span {
                color: variables.$textPassiveState;
              }

              &:hover {
                cursor: pointer;

                .add-button {
                  background-image: url('../../../../assets/icons/closestTracksAddActive1.svg');
                }

                span {
                  color: variables.$textActiveStateWhite;
                }
              }
            }
          }
        }

        .isofi-playlists-container {
          display: flex;
          flex-direction: column;
          margin-top: 30px;
          padding: 8px;
        }
      }

      .caret-container {
        position: absolute;
        padding: 8px 6px;
        background-color: variables.$sideContentBackground;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        width: 25px;
        right: -25px;
        top: 10px;
        overflow: hidden;

        svg {
          font-size: 1rem;
          color: variables.$isofiBrandHighlight;
          z-index: 3;

          &:hover {
            cursor: pointer;
            transform: scale(1.15);
          }
        }
      }

      .divider {
        width: 100%;
        height: 1px;
        background-color: variables.$sidebarBrandText;
      }
    }
  }
}
