@use '../../../styles/variables.scss';

.static-modal-container {
  .title {
    color: variables.$textActiveStateWhite;
  }

  .content {
    color: variables.$textPassiveState;
    height: 50vh;
  }
}
