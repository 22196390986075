//Media queries
@mixin mQ-upTo($arg) {
  @media screen and (max-width: $arg) {
    @content;
  }
}

@mixin mQ-betweenTwo($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin mQ-from($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}
