@use '../../../../styles/variables.scss';

.chart-container {
  position: relative;
  overflow: hidden;
  width: 100%;

  #scatter-chart {
    width: 100%;
    height: 100%;
  }

  .diameter-container {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;

    .circle {
      position: relative;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid variables.$isofiBrandLighter;
      display: inline-block;
    }
  }
}
