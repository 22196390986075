@use '../../styles/variables.scss';
@use '../../styles/mixins.scss';

.playlist-builder-container {
  padding: 0px 0px;
  .graph {
    width: 100%;
    margin: 16px 0px;
    border-radius: 8px;
    padding: 24px;
    background-color: variables.$sideContentBackground;
    .info-and-controls {
      padding: 14px 24px;
      .graph-title {
        color: variables.$textActiveStateWhite;

        img {
          @include mixins.regularIcon();
          margin: 0px 16px;
        }
      }

      .graph-controls {
        button {
          margin: 0px 4px;
        }
      }
    }

    .chart {
      width: 100%;
      .value-y {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        span {
          position: relative;
          color: variables.$textActiveStateWhite;
          transform: rotate(-90deg);
        }
      }

      .value-x {
        width: 100%;
        padding-top: 20px;
        color: variables.$textActiveStateWhite;
        text-align: center;
      }
    }
  }
}
