@use '../../../../../../styles/mixins.scss';
@use '../../../../../../styles/variables.scss';

.isofi-playlists-container {
  .playlist-sidebar-item {
    color: variables.$textPassiveState;

    .playlist-parent-item {
      width: 100%;

      .dropdown-icon-container {
        margin: 5px;
        @include mixins.regularIcon();

        &.expanded {
          background-image: url('../../../../../../assets/icons/generalInteractionArrowWhite.svg');
          transform: rotate(90deg);
        }

        &.collapsed {
          background-image: url('../../../../../../assets/icons/generalInteractionArrowGrey.svg');
        }
      }

      &:hover {
        .playlist-name {
          cursor: pointer;
        }

        .controls-container {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .playlist-child-item {
      overflow: hidden;
      .single-source {
        overflow: hidden;
        margin-left: 24px;
        padding: 5px 0px;
        white-space: nowrap;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          &:active {
            color: variables.$textActiveStateWhite;
          }
        }
      }

      &.expanded {
        height: auto;
      }

      &.collapsed {
        height: 0px;
      }
    }
  }

  .selected {
    background-color: variables.$listItemInFocus;
    color: variables.$textActiveStateWhite;
    border-radius: 4px;
  }
}
