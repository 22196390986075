@use '../../../styles/variables.scss';
@use '../../../styles/mixins.scss';

.modal-container {
  position: relative;
  display: flex;
  align-items: center !important;
  min-width: 660px !important;
  min-height: 90vh !important;
  .modal-content {
    box-shadow: variables.$modalShadow;
    background-color: variables.$sideContentBackground;
    color: variables.$textPassiveState;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
  }

  .close-modal {
    @include mixins.regularIcon();
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
