@use '../../styles/variables.scss';
@use '../../styles/mixins.scss';

$playerSidesWidth: 350px;

.spotify-player-container {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: variables.$surfaceInteractive;
  height: variables.$playerHeight;
  z-index: 5;

  .info-container {
    width: $playerSidesWidth;
    img {
      width: 96px;
      height: 96px;
      margin-left: 24px;
      margin-right: 15px;
    }

    .song-info {
      overflow: hidden;
      white-space: nowrap;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        color: variables.$textActiveStateWhite;
        font-weight: 400;
      }
    }
  }

  .controls-and-track {
    width: 100%;
    padding: 20px 0px;
    .controls {
      margin-bottom: 20px;
      svg {
        @include mixins.mediumIcon();
        margin: 0px 20px;
        color: blue;

        &:hover {
          transform: scale(1.05);
        }

        &.disabled {
          @include mixins.disabledControlInline();
        }
      }
    }

    .track-progress {
      .progress-number {
        color: variables.$textActiveStateWhite;
        min-width: 60px;

        &:first-of-type {
          text-align: left;
        }

        &:last-of-type {
          text-align: right;
        }
      }
    }
  }

  .volume-control {
    position: relative;
    margin-right: 30px;
    width: $playerSidesWidth;

    svg {
      @include mixins.regularIcon();
      margin-right: 11px;
    }

    .volume-invisible {
      @include mixins.mediumIcon();
      visibility: none;
      margin-bottom: 20px;
    }

    .volume-container {
      .volume-slider-container {
        .muted {
          .rc-slider-track {
            background-color: variables.$textActiveStateWhite;
          }
        }
      }
    }
  }

  .volume-slider-container,
  .progress-slider-container {
    .muted {
      .rc-slider-track {
        background-color: variables.$textActiveStateWhite;
      }
    }

    .with-handle {
      &:hover {
        .rc-slider-handle {
          visibility: visible;
          width: 16px;
          height: 16px;
        }
      }
      .rc-slider-handle {
        visibility: hidden;
        background-color: variables.$playerProgressBarColor;
        border-color: variables.$playerProgressBarColor;
      }
    }

    .rc-slider-rail {
      background-color: variables.$textActiveStateWhite;
    }

    .rc-slider-track {
      background-color: variables.$playerProgressBarColor;
    }

    .rc-slider-tooltip-inner {
      background-color: variables.$textActiveStateWhite;
      color: variables.$obsidian;
      font-family: Inter;
    }

    .rc-slider-handle-dragging {
      box-shadow: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
