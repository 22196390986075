@use '../../styles/variables.scss';
@use '../../styles/mixins.scss';

.playlist-sources-container {
  .title {
    color: variables.$textActiveStateWhite;
  }

  .profile-container {
    margin-top: 62px;
    margin-bottom: 36px;
    img {
      height: 120px;
      width: 120px;
      border: 2px solid variables.$whiteColor;
      border-radius: 50%;
    }

    .profile-info {
      margin-left: 32px;
      padding: 2px 0px;

      button {
        height: 24px;
        margin: 3px 0px;
      }

      .username {
        color: variables.$textPassiveState;
      }
    }
  }

  .playlist-container {
    width: 100%;
    padding: 14px 0px;
    .title {
      margin-bottom: 14px;
      padding: 0px 23px;
    }

    .tracks-container {
      border-bottom: 1px solid variables.$dividerColor;
      padding: 0px 23px;
      .track-info {
        img {
          width: 26px;
          height: 26px;
        }
        .playlist-name {
          display: inline-block;
          color: variables.$textPassiveState;
          padding: 10px 0px;
          margin-left: 15px;
          max-width: 570px;
          text-overflow: ellipsis;
          max-height: 2.2em;
        }
      }

      .track-actions {
        .remove-playlist,
        .add-playlist {
          @include mixins.regularIcon();
          margin-right: 12px;
        }

        .add-playlist {
          background-image: url('../../assets/icons/closestTracksAddPassive2.svg');
        }

        .remove-playlist {
          background-image: url('../../assets/icons/closestTracksRemovePassive2.svg');
        }

        .button-text {
          color: variables.$textPassiveState;
        }

        &.disabled {
          @include mixins.disabledControlInline();
        }
      }

      .refresh-single-playlist {
        .refresh-icon {
          color: variables.$textPassiveState;
          @include mixins.regularIcon();
          margin-right: 24px;

          &:hover {
            color: variables.$isofiBrandLighter;
            transition: transform 0.25s ease-in;
            transform: scale(1.1);
          }
        }
        &.disabled {
          @include mixins.disabledControlInline();
        }
      }

      &:hover {
        background-color: variables.$listItemInFocus;

        .track-actions {
          cursor: pointer;
        }

        &:last-of-type {
          border-radius: 8px;
        }

        .add-playlist {
          background-image: url('../../assets/icons/closestTracksAddActive1.svg');
        }

        .remove-playlist {
          background-image: url('.././../assets/icons/closestTracksRemoveActive2.svg');
        }
      }
    }
  }

  .spotify-sources {
    background-color: variables.$sideContentBackground;
    border-radius: 8px;
  }
}
