@use '../../styles/variables.scss';

.page-404-container {
  width: 100%;
  height: 100vh;
  background-color: variables.$appBackground;

  .page-404-holder {
    text-align: center;
    width: 50vw;
    height: 50vh;

    .page-404-title {
      font-size: 2.5em;
      color: variables.$lightText;
      margin-bottom: 50px;
    }
  }
}
