@use './variables.scss';
@use './mixins.scss';
// @import '~bootstrap/scss/bootstrap.scss'; // !FIXME: Figure out how to include bootstrap without crashing `npm run build`

// $theme-colors: (
//   'primary': variables.$isofiBrandLighter,
// );

$buttonPadding: 5px 16px 7px 16px;

.btn-primary {
  background-color: variables.$isofiBrandLighter;
  color: variables.$isofiBrandDarker;
  padding: $buttonPadding;
  border-radius: 4px;
  border: none;

  &:disabled {
    @include mixins.disabledControl();
  }

  &:hover,
  &:active {
    color: variables.$textActiveStateWhite;
    background-color: variables.$isofiBrandDarker;
  }

  &:focus {
    background-color: variables.$textActiveStateWhite;
    color: variables.$isofiBrandDarker;
    border-color: variables.$isofiBrandDarker;
    box-shadow: none;
  }
}

.btn-secondary {
  border: 1px solid variables.$textListPassive;
  border-radius: 4px;
  background-color: transparent;
  color: variables.$textActiveStateWhite;
  padding: $buttonPadding;

  &:disabled {
    @include mixins.disabledControl();
  }

  &:hover {
    background-color: variables.$obsidian;
  }
}
