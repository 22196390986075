@use '../../../../styles/variables.scss';
@use '../../../../styles/mixins.scss';

.closest-tracks-container {
  background-color: variables.$sideContentBackground;
  border-radius: 8px;
  width: 100%;
  min-width: variables.$minMainContentWidth;
  .closest-tracks-title {
    color: variables.$textActiveStateWhite;
    padding: 14px 24px;
    img {
      @include mixins.regularIcon();
      margin: 0px 16px;
    }
  }

  .closest-tracks-table {
    padding: 12px 24px;

    table {
      td {
        border: none;
      }

      tr {
        border-bottom: 1px solid variables.$dividerColor1;

        &.table-body:hover {
          background-color: variables.$listItemInFocus;
        }
      }

      th {
        border-bottom: 1px solid variables.$dividerColor1;
      }

      .active {
        background-color: variables.$listItemInFocus;
      }
    }

    .empty-results-message {
      width: 100%;
      color: variables.$textPassiveState;

      td {
        pointer-events: none;
      }
    }

    .column-header {
      color: variables.$textPassiveState;
      text-align: left;
    }
  }
}
