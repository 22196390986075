@use '../../../styles/variables.scss';
@use '../../../styles/shared.scss';

.share-playlist-modal-container {
  min-height: 500px;
  padding: 0px 60px;

  .playlist-modal-title {
    margin-top: 72px;
    color: variables.$textActiveStateWhite;
  }

  .playlist-modal-subtitle {
    color: variables.$textPassiveState;
    .subtitle-item {
      margin-bottom: 10px;
    }
  }

  .playlist-modal-body {
    margin-bottom: 50px;
    width: 100%;
    input {
      width: 100%;
      margin-bottom: 20px;
      padding: 5px 10px;
      word-break: break-word;
      text-align: center;
    }
    .copied {
      background-color: variables.$playingIconColor;
    }
  }
}
