@use '../../../styles/variables.scss';
@use '../../../styles/mixins';

.track-unique {
  span {
    color: variables.$textPassiveState;
  }

  .playing {
    color: variables.$playingIconColor;
  }

  .paused {
    color: variables.$pausedIconColor;
  }

  .play-track {
    color: variables.$textActiveStateWhite;
  }

  svg {
    &:hover {
      cursor: pointer;
    }
  }
}
