@use '../../../styles/variables.scss';
@use '../../../styles/shared.scss';

$elementMargins: 30px 0px;

.isofi-playlist-name-modal-container {
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  label {
    margin: $elementMargins;
  }

  input {
    margin: $elementMargins;
    width: 80%;
  }

  button {
    margin: $elementMargins;
  }
}
