// Colors
$surfaceInteractive: #221f1f;
$surfaceInteractiveSecondary: #8e8e8e;
$isofiBrandHighlight: hsl(1, 67%, 76%);
$isofiBrandLighter: #ffcdc9;
$isofiBrandDarker: #6e2926;
$textActiveStateWhite: #ffffff;
$textPassiveState: #adadad;
$textListPassive: #9b9797;
$obsidian: #000000;
$dividerColor: rgba(0, 0, 0, 0.12);
$listItemInFocus: #696666;
$backgroundSecondary: #262525;
$dividerColor1: #3a3939;
$playerProgressBarColor: #ea9b99;

$playingIconColor: #00bc42;
$pausedIconColor: #d98578;
$sideContentBackground: #434141;
$displayName: #aeaeae;
$sidebarBrandText: #8e8e8e;
$backgroundBgPrimary: #8e8e8e;

$mainDark: #2c2a2a;
$mainLight: #333333;
$header: #221f1f;
$lightText: #8e8e8e;
$appBackground: #333333;
$whiteColor: #ffffff;
$buttonBox: #858282;
$modalShadow: 0 0 60px 0 rgba(0, 0, 0, 0.6);

// Size
$headerHeight: 64px;
$leftSidebarWidth: 236px;
$leftSidebarMargin: 24px;
$rightSidebarWidth: 330px;
$playerHeight: 128px;
$minMainContentWidth: 800px;

//Animations
$sidebarPullDuration: 0.25s;

// Paddings
$appTitleLeftPadding: 20px;
$headerSidePadding: 15px;
