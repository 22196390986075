@use '../../styles/variables.scss';

.spinner-container {
  width: 100%;
  height: 100%;
  padding: 50px 0px;

  div {
    width: 150px;
    height: 150px;
  }
}
