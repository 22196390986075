@use './variables.scss';

@mixin regularIcon($minWidth: false) {
  width: 24px;
  height: 24px;

  &:hover {
    cursor: pointer;
  }

  @if $minWidth {
    min-width: 24px;
    min-height: 24px;
  }
}

@mixin sidebarLeftPadding() {
  padding-left: 24px;
}

@mixin mediumIcon() {
  width: 48px;
  height: 48px;

  &:hover {
    cursor: pointer;
  }
}

@mixin disabledControl() {
  pointer-events: none !important;
  background-color: variables.$obsidian;
  opacity: 0.3;
}

@mixin disabledControlInline() {
  pointer-events: none !important;
  color: variables.$obsidian;
  opacity: 0.3;
}
