@use '../../styles/variables.scss';
@use '../../styles/mediaqueries.scss';

.home-container {
  .body-container {
    .main-content {
      min-width: variables.$minMainContentWidth;
      margin: 16px 0px;
      flex-grow: 1;
      margin-left: (variables.$leftSidebarWidth + variables.$leftSidebarMargin);
    }
  }

  @include mediaqueries.mQ-from(2220px) {
    max-width: 1920px;
    align-self: center;
  }
}
